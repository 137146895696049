import React, {Suspense, useEffect, useState} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import SumOtherPayment from "./components/Content/Informer/SumOtherPayment/SumOtherPayment";
import {ScrollToTop} from "./components/ScrollToTop/ScrollToTop";
import FooterSec from "./components/Footer/FooterSec";

const Loader = React.lazy(() => import("./components/Loader/Loader"));
const Head = React.lazy(() => import("./components/Content/Head/Head"));
const Navbar = React.lazy(() => import("./components/Navbar/Navbar"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const Minister = React.lazy(() => import("./components/Content/Minister/Minister"));
const MinisterStaffBiographies = React.lazy(() => import("./components/Content/MinisterStaffBiographies/MinisterStaffBiographies"));
const HistoricalOverviews = React.lazy(() => import("./components/Content/Ministry/HistoricalOverviews/HistoricalOverviews"));
const OurMinisters = React.lazy(() => import("./components/Content/Ministry/OurMinisters/OurMinisters"));
const OureMinister = React.lazy(() => import("./components/Content/Ministry/OurMinisters/OureMinister"));
const Statute = React.lazy(() => import("./components/Content/Ministry/Statute/Statute"));
const Checklist = React.lazy(() => import("./components/Content/Ministry/Checklist/Checklist"));
const ChecklistWithParent = React.lazy(() => import("./components/Content/Ministry/Checklist/ChecklistWithParent"));

const AttachedMinisters = React.lazy(() => import("./components/Content/Ministry/AttachedMinisters/AttachedMinisters"));
const Message = React.lazy(() => import("./components/Content/Minister/Message/Message"));
const DayReports = React.lazy(() => import("./components/Content/Minister/DayReports/DayReports"));
const NewsPage = React.lazy(() => import("./components/Content/InformationPlatform/NewsPage/NewsPage"));
const NewsItem = React.lazy(() => import("./components/Content/InformationPlatform/NewsPage/NewsItem"));
const VideoItem = React.lazy(() => import("./components/Content/InformationPlatform/Videos/VideoItem"));
const VacanciesPage = React.lazy(() => import("./components/Content/InformationPlatform/VacansiesPage/VacanciesPage"));
const StaffOfTheMinister = React.lazy(() => import("./components/Content/Staff/StaffOfTheMinister/StaffOfTheMinister"));
const StaffOfTheMinistry = React.lazy(() => import("./components/Content/Staff/StaffOfTheMinistry/StaffOfTheMinistry"));
const BodySubordinateToTheministry = React.lazy(() => import("./components/Content/Staff/BodySubordinateToTheministry/BodySubordinateToTheministry"));
const InfrastructureOfTheMinistry = React.lazy(() => import("./components/Content/Staff/InfrastructureOfTheMinistry/InfrastructureOfTheMinistry"));
const StateNonProfitOrganizations = React.lazy(() => import("./components/Content/Staff/StateNonProfitOrganizations/StateNonProfitOrganizations"));
const Budget = React.lazy(() => import("./components/Content/Ministry/Budget/Budget"));
const BudgetItem = React.lazy(() => import("./components/Content/Ministry/Budget/BudgetItem"));
const BudgetSubItem = React.lazy(() => import("./components/Content/Ministry/Budget/BudgetSubItem"));
const Procedure = React.lazy(() => import("./components/Content/Informer/Procedure/Procedure"));
const InformationOfficer = React.lazy(() => import("./components/Content/Informer/InformationOfficer/InformationOfficer"));
const Certifications = React.lazy(() => import("./components/Content/Informer/Certifications/Certifications"));
const Report = React.lazy(() => import("./components/Content/Ministry/Report/Report"));
const ReportWithParent = React.lazy(() => import("./components/Content/Ministry/Report/ReportWithParent"));
const Procurement = React.lazy(() => import("./components/Content/Ministry/Procurement/Procurement"));
const ProcurementWithParent = React.lazy(() => import("./components/Content/Ministry/Procurement/ProcurementWithParent"));
const Employment = React.lazy(() => import("./components/Content/QuestionandAnswer/Employment/Employment"));
const EmploymentItem = React.lazy(() => import("./components/Content/QuestionandAnswer/Employment/EmploymentItem"));
const EnsuringEqualOpportunity = React.lazy(() => import("./components/Content/QuestionandAnswer/EnsuringEqualOpportunity/EnsuringEqualOpportunity"));
const EnsuringEqualOpportunityItem = React.lazy(() => import("./components/Content/QuestionandAnswer/EnsuringEqualOpportunity/EnsuringEqualOpportunityItem"));
const IssuesElderly = React.lazy(() => import("./components/Content/QuestionandAnswer/IssuesElderly/IssuesElderly"));
const MedicalSocialExpertises = React.lazy(() => import("./components/Content/QuestionandAnswer/MedicalSocialExpertises/MedicalSocialExpertises"));
const Question = React.lazy(() => import("./components/Content/QuestionandAnswer/Question/Question"));
const SocialSupport = React.lazy(() => import("./components/Content/QuestionandAnswer/SocialSupport/SocialSupport"));
const PensionSecurity = React.lazy(() => import("./components/Content/QuestionandAnswer/PensionSecurity/PensionSecurity"));
const PensionItem = React.lazy(() => import("./components/Content/QuestionandAnswer/PensionSecurity/PensionItem"));
const IssuesOfPersonsWithDisability = React.lazy(() => import("./components/Content/QuestionandAnswer/IssuesOfPersonsWithDisability/IssuesOfPersonsWithDisability"));
const SocialCooperations = React.lazy(() => import("./components/Content/Informer/SocialCooperations/SocialCooperations"));
const SocialCooperationItem = React.lazy(() => import("./components/Content/Informer/SocialCooperations/SocialCooperationItem"));
const Videos = React.lazy(() => import("./components/Content/InformationPlatform/Videos/Videos"));
const NationalCommissions = React.lazy(() => import("./components/Content/Informer/NationalCommissions/NationalCommissions"));
const NationalCommissionItems = React.lazy(() => import("./components/Content/Informer/NationalCommissions/NationalCommissionItems"));
const Announcements = React.lazy(() => import("./components/Content/Informer/Announcements/Announcements"));
const AnnouncementItem = React.lazy(() => import("./components/Content/Informer/Announcements/AnnouncementItem"));
const PublicDiscussions = React.lazy(() => import("./components/Content/Informer/PublicDiscussions/PublicDiscussions"));
const Competitions = React.lazy(() => import("./components/Content/Informer/Competitions/Competitions"));
const CompetitionItem = React.lazy(() => import("./components/Content/Informer/Competitions/CompetitionItem"));
const EducationalCourses = React.lazy(() => import("./components/Content/Informer/EducationalCourses/EducationalCourses"));
const EducationalCoursesWithParent = React.lazy(() => import("./components/Content/Informer/EducationalCourses/EducationalCoursesWithParent"));
const ProfessionalOrientation = React.lazy(() => import("./components/Content/QuestionandAnswer/ProfessionalOrientation/ProfessionalOrientation"));
const BlockItem = React.lazy(() => import("./components/Content/Head/BlockItem/BlockItem"));
const BlockPage = React.lazy(() => import("./components/Content/Head/BlockPage/BlockPage"));
const Secretary = React.lazy(() => import("./components/Content/Secretary/Secretary"));
const ExternalConnection = React.lazy(() => import("./components/Content/ExternalConnection/ExternalConnection"));
const ExternalConnectionBlog = React.lazy(() => import("./components/Content/ExternalConnection/ExternalConnectionBlog"));
const SecretaryBlog = React.lazy(() => import("./components/Content/Secretary/SecretaryBlog"));
const MemorandumsPage = React.lazy(() => import("./components/Content/InformationPlatform/MemorandumsPage/MemorandumsPage"));
const MemorandumsItem = React.lazy(() => import("./components/Content/InformationPlatform/MemorandumsPage/MemorandumsItem"));
const Feedback = React.lazy(() => import("./components/Content/InformationPlatform/Feedback/Feedback"));
const CompetitionResultsItems = React.lazy(() => import("./components/Content/Informer/CompetitionResults/CompetitionResultItems"));
const CompetitionResultPage = React.lazy(() => import("./components/Content/Informer/CompetitionResults/CompetitionResultsPage"));
const MinistryVacancies = React.lazy(() => import("./components/Content/Informer/MinistryVacancies/MinistryVacancies"));
const MinistryVacanciesItems = React.lazy(() => import("./components/Content/Informer/MinistryVacancies/MinistryVacanciesItems"));
const FreedomInformationOfficer = React.lazy(() => import("./components/Content/InformationCenter/InformationOfficer/FreedomInformationOfficer"));
const CorruptionPrograms = React.lazy(() => import("./components/Content/InformationCenter/CorruptionPrograms/CorruptionPrograms"));
const NotificationOfficer = React.lazy(() => import("./components/Content/InformationCenter/NotificationOfficer/NotificationOfficer"));
const InformationRequests = React.lazy(() => import("./components/Content/InformationCenter/InformationRequests/InformationRequests"));
const ApplicationTheory = React.lazy(() => import("./components/Content/InformationCenter/ApplicationTheory/ApplicationTheory"));
const Banks = React.lazy(() => import("./components/Content/Banners/Banks/Banks"));
const ImprovingPrograms = React.lazy(() => import("./components/Content/Banners/ImprovingPrograms/ImprovingPrograms"));
const AvailabilityStatements = React.lazy(() => import("./components/Content/Banners/AvailabilityStatements/AvailabilityStatements"));
const ProcessingPersonalDatas = React.lazy(() => import("./components/Content/Banners/ProcessingPersonalDatas/ProcessingPersonalDatas"));
const ProstheticOrthopedics = React.lazy(() => import("./components/Content/Banners/ProstheticOrthopedics/ProstheticOrthopedics"));
const ForeignCountriesBusinessTrip = React.lazy(() => import("./components/Content/Banners/ForeignCountriesBusinessTrip/ForeignCountriesBusinessTrip"));
const ExpertReports = React.lazy(() => import("./components/Content/PersonnelManagement/ExpertReports/ExpertReports"));
const ExpertReportsItem = React.lazy(() => import("./components/Content/PersonnelManagement/ExpertReports/ExpertReportsItem"));
const StatisticsApplications = React.lazy(() => import("./components/Content/InformationCenter/StatisticsApplications/StatisticsApplications"));
const StatisticsApplicationsItem = React.lazy(() => import("./components/Content/InformationCenter/StatisticsApplications/StatisticsApplicationsItem"));
const Redistributions = React.lazy(() => import("./components/Content/InformationCenter/Redistributions/Redistributions"));
const RedistributionsItem = React.lazy(() => import("./components/Content/InformationCenter/Redistributions/RedistributionsItem"));
const BusinessTripReports = React.lazy(() => import("./components/Content/InformationCenter/BusinessTripReports/BusinessTripReports"));
const BusinessTripReportsItem = React.lazy(() => import("./components/Content/InformationCenter/BusinessTripReports/BusinessTripReportsItem"));
const AnnualPrograms = React.lazy(() => import("./components/Content/InformationCenter/AnnualPrograms/AnnualPrograms"));
const AnnualProgramsItem = React.lazy(() => import("./components/Content/InformationCenter/AnnualPrograms/AnnualProgramsItem"));
const NpoSummaries = React.lazy(() => import("./components/Content/Staff/NpoSummaries/NpoSummaries"));
const NpoSummariesWithParent = React.lazy(() => import("./components/Content/Staff/NpoSummaries/NpoSummariesWithParent"));
const SearchResults = React.lazy(() => import("./components/SearchContent/SearchResults"));
const SearchComponent = React.lazy(() => import("./components/SearchContent/SearchComponent"));


export default function App() {
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        let logedIn = localStorage.getItem('owt-token');
        if (logedIn === undefined || logedIn == null || logedIn === "") {
            setIsLogin(false);
        } else {
            setIsLogin(true);
        }
    }, [isLogin]);


    // useEffect(() => {
    //     const id = setInterval(() => {
    //         axios.get(url+"/api/is_login", {
    //             cancelToken: signal.token,
    //         })
    //             .then((response) => {
    //                 if(response.data == 1){
    //                     setIsLogin(true);
    //                 }else{
    //                     setIsLogin(false);
    //                 }
    //             })
    //             .catch(() => {
    //                 console.log('error');
    //             })
    //     }, 5000);
    //     return () => clearInterval(id);
    // }, [isLogin]);


    return (
        <Router>
        <div className="App">
                <ScrollToTop/>
                <Navbar/>
                <Suspense fallback={<div>Loading...</div>}>
                <Routes>

                    <Route path="/"
                           element={<React.Suspense fallback={<Loader/>}><Head isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/news"
                           element={<React.Suspense fallback={<Loader/>}><NewsPage
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/minister"
                           element={<React.Suspense fallback={<Loader/>}><Minister
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/message"
                           element={<React.Suspense fallback={<Loader/>}><Message
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/100-day-report"
                           element={<React.Suspense fallback={<Loader/>}><DayReports
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/historical-overviews"
                           element={<React.Suspense fallback={<Loader/>}><HistoricalOverviews
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/our-ministers"
                           element={<React.Suspense fallback={<Loader/>}><OurMinisters
                               isLogin={isLogin}/></React.Suspense>}/>


                    <Route exact path="/our-minister/:id"
                           element={<React.Suspense fallback={<Loader/>}><OureMinister
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/statute"
                           element={<React.Suspense fallback={<Loader/>}><Statute
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/checklist"
                           element={<React.Suspense fallback={<Loader/>}><Checklist
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/checklist/:id"
                           element={<React.Suspense fallback={<Loader/>}><ChecklistWithParent
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/public-council-attached-to-the-minister"
                           element={<React.Suspense fallback={<Loader/>}><AttachedMinisters
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/budget"
                           element={<React.Suspense fallback={<Loader/>}><Budget isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/budget-item/:id"
                           element={<React.Suspense fallback={<Loader/>}><BudgetItem
                               isLogin={isLogin}/></React.Suspense>}/>


                    <Route exact path="/budget-sub-item/:id"
                           element={<React.Suspense fallback={<Loader/>}><BudgetSubItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/report"
                           element={<React.Suspense fallback={<Loader/>}><Report isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/reports/:id"
                           element={<React.Suspense fallback={<Loader/>}><ReportWithParent
                               isLogin={isLogin}/></React.Suspense>}/>


                    <Route exact path="/procurement"
                           element={<React.Suspense fallback={<Loader/>}><Procurement
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/educational_courses"
                           element={<React.Suspense fallback={<Loader/>}><EducationalCourses
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/educational_courses/parent/:id"
                           element={<React.Suspense fallback={<Loader/>}><EducationalCoursesWithParent
                               isLogin={isLogin}/></React.Suspense>}/>


                    <Route exact path="/procurement/:id"
                           element={<React.Suspense fallback={<Loader/>}><ProcurementWithParent
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/secretary"
                           element={<React.Suspense fallback={<Loader/>}><Secretary
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/external-connection"
                           element={<React.Suspense fallback={<Loader/>}><ExternalConnection
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/sum-other-payments"
                           element={<React.Suspense fallback={<Loader/>}><SumOtherPayment
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/news/:id"
                           element={<React.Suspense fallback={<Loader/>}><NewsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/vacancies"
                           element={<React.Suspense fallback={<Loader/>}><VacanciesPage
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route path="/staff-of-the-minister"
                           element={<React.Suspense fallback={<Loader/>}><StaffOfTheMinister
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route path="/staff-of-the-ministry"
                           element={<React.Suspense fallback={<Loader/>}><StaffOfTheMinistry
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route path="/body-subordinate-to-the-ministry" element={<React.Suspense
                        fallback={<Loader/>}><BodySubordinateToTheministry isLogin={isLogin}/></React.Suspense>}/>

                    <Route path="/state-non-profit-organizations" element={<React.Suspense
                        fallback={<Loader/>}><StateNonProfitOrganizations isLogin={isLogin}/></React.Suspense>}/>

                    <Route path="/infrastructure-of-the-ministry" element={<React.Suspense
                        fallback={<Loader/>}><InfrastructureOfTheMinistry isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/procedure-for-receiving-information"
                           element={<React.Suspense fallback={<Loader/>}><Procedure
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/employment"
                           element={<React.Suspense fallback={<Loader/>}><Employment
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/employment-item/:id"
                           element={<React.Suspense fallback={<Loader/>}><EmploymentItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/ensuring-equal-opportunity"
                           element={<React.Suspense fallback={<Loader/>}><EnsuringEqualOpportunity
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/ensuring-equal-opportunity-item/:id"
                           element={<React.Suspense fallback={<Loader/>}><EnsuringEqualOpportunityItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/issues_elderly"
                           element={<React.Suspense fallback={<Loader/>}><IssuesElderly
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/medical-social-expertises"
                           element={<React.Suspense fallback={<Loader/>}><MedicalSocialExpertises
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/question"
                           element={<React.Suspense fallback={<Loader/>}><Question
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/social_support"
                           element={<React.Suspense fallback={<Loader/>}><SocialSupport
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/pension-security"
                           element={<React.Suspense fallback={<Loader/>}><PensionSecurity
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/pension-item/:id"
                           element={<React.Suspense fallback={<Loader/>}><PensionItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/issues-of-persons-with-disabilities"
                           element={<React.Suspense fallback={<Loader/>}><IssuesOfPersonsWithDisability
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/freedom-of-Information-officer"
                           element={<React.Suspense fallback={<Loader/>}><InformationOfficer
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/certifications"
                           element={<React.Suspense fallback={<Loader/>}><Certifications
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/professional-orientation"
                           element={<React.Suspense fallback={<Loader/>}><ProfessionalOrientation
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/social-cooperations"
                           element={<React.Suspense fallback={<Loader/>}><SocialCooperations
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/social-cooperation/:id"
                           element={<React.Suspense fallback={<Loader/>}><SocialCooperationItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/videos"
                           element={<React.Suspense fallback={<Loader/>}><Videos isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/video/:id"
                           element={<React.Suspense fallback={<Loader/>}><VideoItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/national-commissions"
                           element={<React.Suspense fallback={<Loader/>}><NationalCommissions
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/national-commission/:id"
                           element={<React.Suspense fallback={<Loader/>}><NationalCommissionItems
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/announcements"
                           element={<React.Suspense fallback={<Loader/>}><Announcements
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/announcement/:id"
                           element={<React.Suspense fallback={<Loader/>}><AnnouncementItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/public-discussions"
                           element={<React.Suspense fallback={<Loader/>}><PublicDiscussions
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/competitions"
                           element={<React.Suspense fallback={<Loader/>}><Competitions
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/competition/:id"
                           element={<React.Suspense fallback={<Loader/>}><CompetitionItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/external-connection/blog/:id"
                           element={<React.Suspense fallback={<Loader/>}><ExternalConnectionBlog
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/secretary/blog/:id"
                           element={<React.Suspense fallback={<Loader/>}><SecretaryBlog
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/memorandums/:id"
                           element={<React.Suspense fallback={<Loader/>}><MemorandumsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/memorandums"
                           element={<React.Suspense fallback={<Loader/>}><MemorandumsPage
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/feedback"
                           element={<React.Suspense fallback={<Loader/>}><Feedback
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/blockpage/:id"
                           element={<React.Suspense fallback={<Loader/>}><BlockItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/blockpage/:page/:Id/:lang"
                           element={<React.Suspense fallback={<Loader/>}><BlockPage
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/competition-results/:id"
                           element={<React.Suspense fallback={<Loader/>}><CompetitionResultsItems
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/competition-results"
                           element={<React.Suspense fallback={<Loader/>}><CompetitionResultPage
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/ministry-vacancies"
                           element={<React.Suspense fallback={<Loader/>}><MinistryVacancies
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/ministry-vacancy/:id"
                           element={<React.Suspense fallback={<Loader/>}><MinistryVacanciesItems
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/freedom-information-officers"
                           element={<React.Suspense fallback={<Loader/>}><FreedomInformationOfficer
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/anti-corruption-program-officers"
                           element={<React.Suspense fallback={<Loader/>}><CorruptionPrograms
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/notification-officers"
                           element={<React.Suspense fallback={<Loader/>}><NotificationOfficer
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/statistic-information-requestes"
                           element={<React.Suspense fallback={<Loader/>}><InformationRequests
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/application-theories"
                           element={<React.Suspense fallback={<Loader/>}><ApplicationTheory
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/banks"
                           element={<React.Suspense fallback={<Loader/>}><Banks
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/improving-demographic-situation-programs"
                           element={<React.Suspense fallback={<Loader/>}><ImprovingPrograms
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/availability-statements"
                           element={<React.Suspense fallback={<Loader/>}><AvailabilityStatements
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/processing-personal-datas"
                           element={<React.Suspense fallback={<Loader/>}><ProcessingPersonalDatas
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/prosthetic-orthopedics"
                           element={<React.Suspense fallback={<Loader/>}><ProstheticOrthopedics
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/foreign-countries-business-trip"
                           element={<React.Suspense fallback={<Loader/>}><ForeignCountriesBusinessTrip
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/expert-reports"
                           element={<React.Suspense fallback={<Loader/>}><ExpertReports
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/expert-report-sub-items/:id"
                           element={<React.Suspense fallback={<Loader/>}><ExpertReportsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/statistics-inquiries-applications"
                           element={<React.Suspense fallback={<Loader/>}><StatisticsApplications
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/statistics-inquiries-applications-sub-items/:id"
                           element={<React.Suspense fallback={<Loader/>}><StatisticsApplicationsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/redistributions"
                           element={<React.Suspense fallback={<Loader/>}><Redistributions
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/redistributions-sub-items/:id"
                           element={<React.Suspense fallback={<Loader/>}><RedistributionsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/business-trip-reports"
                           element={<React.Suspense fallback={<Loader/>}><BusinessTripReports
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/business-trip-reports-sub-items/:id"
                           element={<React.Suspense fallback={<Loader/>}><BusinessTripReportsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/annual-programs"
                           element={<React.Suspense fallback={<Loader/>}><AnnualPrograms
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/annual-programs-sub-items/:id"
                           element={<React.Suspense fallback={<Loader/>}><AnnualProgramsItem
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/minister-staff-biographies/:id"
                           element={<React.Suspense fallback={<Loader/>}><MinisterStaffBiographies
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/npo-summaries"
                           element={<React.Suspense fallback={<Loader/>}><NpoSummaries
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/npo-summaries/:id"
                           element={<React.Suspense fallback={<Loader/>}><NpoSummariesWithParent
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/search-results"
                           element={<React.Suspense fallback={<Loader/>}><SearchResults
                               isLogin={isLogin}/></React.Suspense>}/>

                    <Route exact path="/search"
                           element={<React.Suspense fallback={<Loader/>}><SearchComponent
                               isLogin={isLogin}/></React.Suspense>}/>


                </Routes>
            </Suspense>
            <Footer/>
            <FooterSec/>
        </div>
      </Router>
    );
}
