// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrollToTop_topButton__YXYZ6 {
    display: block;
    width: 65px;
    height: 55px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #0F579E;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
}

.ScrollToTop_topButton__YXYZ6 img {
    width: 100%;
}

/* Responsive styles for mobile screens (adjust breakpoint as needed) */
@media screen and (max-width: 767px) {
    .ScrollToTop_topButton__YXYZ6 {
        width: 55px; /* Decreased width for mobile */
        height: 50px;
        bottom: 10px; /* Further decreased distance from the bottom for mobile */
        right: 10px; /* Further decreased distance from the right for mobile */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollToTop/ScrollToTop.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA,uEAAuE;AACvE;IACI;QACI,WAAW,EAAE,+BAA+B;QAC5C,YAAY;QACZ,YAAY,EAAE,0DAA0D;QACxE,WAAW,EAAE,yDAAyD;IAC1E;AACJ","sourcesContent":[".topButton {\n    display: block;\n    width: 65px;\n    height: 55px;\n    position: fixed;\n    bottom: 30px;\n    right: 30px;\n    background-color: #0F579E;\n    color: white;\n    border: none;\n    border-radius: 50%;\n    cursor: pointer;\n    z-index: 999;\n}\n\n.topButton img {\n    width: 100%;\n}\n\n/* Responsive styles for mobile screens (adjust breakpoint as needed) */\n@media screen and (max-width: 767px) {\n    .topButton {\n        width: 55px; /* Decreased width for mobile */\n        height: 50px;\n        bottom: 10px; /* Further decreased distance from the bottom for mobile */\n        right: 10px; /* Further decreased distance from the right for mobile */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topButton": `ScrollToTop_topButton__YXYZ6`
};
export default ___CSS_LOADER_EXPORT___;
