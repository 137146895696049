import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const isDevelopment = process.env.NODE_ENV === 'development';

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "hy",
        lng: 'hy',
        interpolation: {
            escapeValue: false
        }
    }, (error) => {
        if (error) {
            if (isDevelopment) {
                console.error("i18next initialization failed", error);
            } else {
                // Handle error reporting in production (e.g., send to monitoring service)
            }
        } else {
            if (isDevelopment) {
                console.log("i18next initialized successfully");
            }
            // Additional initialization logic can go here if needed
        }
    });
