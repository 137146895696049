import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import style from "./ScrollToTop.module.css";
import ArrowTop from "./../../assets/images/arrow_top.svg"

export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Scroll to top when the route changes
        scrollToTop();
    }, [location.pathname]); // Trigger effect on route change

    return (
        <button className={style.topButton} onClick={scrollToTop} style={{ display: isVisible ? 'block' : 'none' }}>
            <img src={ArrowTop} alt="" />
        </button>
    );
};
