import React from "react";
import style from './FooterSec.module.css';
import Coat from './../../assets/images/coat-of-arms-big.svg';
import Copyright from './../../assets/images/copyright-regular.svg'
import NorkLogo from "../../assets/images/NorkLogo.png";
import {useTranslation} from "react-i18next";

export default function FooterSec() {
    const  { t } = useTranslation();

    return (
        <>
            <div className={style.footerSec}>
                <div className={style.coat}>
                    <img src={Coat} alt=""/>
                </div>
                <div className={style.footerText}>
                    {/*<div style={{display: 'flex', gap: 10}}>*/}
                    {/*    <img src={UserCheck} alt=""/>*/}
                    {/*    <p>«Կայքն առաջիկայում լիարժեք կհամապատասխանեցվի Հայաստանի թվային ծառայությունների նախագծման ստանդարտներին»։</p>*/}
                    {/*</div>*/}
                    <div style={{display: 'flex', gap: 10}}>
                        <img src={Copyright} alt=""/>
                        <p> {new Date().getFullYear()} {t('title')}</p>

                    </div>
                </div>
            </div>
            <div className={style.FootBlockPrivacy}>
                <a href={'https://nork.am/'}>
                    <img src={NorkLogo} style={{height: '115px'}} alt=""/>
                    {t('nork')} {new Date().getFullYear()}
                </a>
            </div>
            <div className={style.otherPages}>
                <a href="/processing-personal-datas" target="_blank">{t('ProcessingPersonalDatas')}</a>
                <span>|</span>
                <a href="/availability-statements" target="_blank">{t('AvailabilityStatements')}</a>
            </div>
        </>
    )
}