// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SumOtherPayment_MinisterBlock__OZ55X {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #FFF;
    margin: 100px 15px -80px;
    padding-bottom: 15px;
}

.SumOtherPayment_MinisterImg__o\\+KKu>h1 {
    text-transform: uppercase;
    color: #0F579E;
    padding: 30px;
    text-align: end;
}

.SumOtherPayment_MinisterText__2PAvp {
    background-color: #FFF;
    padding: 30px;
}

.SumOtherPayment_MinImg__0m-Pj {
    display: flex;
    justify-content: flex-end;
}

.SumOtherPayment_MinImg__0m-Pj>img {
    width: 700px;
    object-fit: contain;
}

.SumOtherPayment_FullSlider__rZ03F>div>div:last-child {
    bottom: 30px;
}

.SumOtherPayment_FullSlider__rZ03F>div>div:last-child>span {
    width: 50px;
    height: 10px;
    border-radius: 0;
    opacity: 1;
}

.SumOtherPayment_FullSlider__rZ03F>div>div>span.swiper-pagination-bullet {
    width: 50px;
    height: 10px;
    border-radius: 0;
    opacity: 1;
    background: #FFF;
}

.SumOtherPayment_FullSlider__rZ03F>div>div>span.swiper-pagination-bullet-active {
    background: #0F579E;
}`, "",{"version":3,"sources":["webpack://./src/components/Content/Informer/SumOtherPayment/SumOtherPayment.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,sBAAsB;IACtB,wBAAwB;IACxB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".MinisterBlock {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    background-color: #FFF;\n    margin: 100px 15px -80px;\n    padding-bottom: 15px;\n}\n\n.MinisterImg>h1 {\n    text-transform: uppercase;\n    color: #0F579E;\n    padding: 30px;\n    text-align: end;\n}\n\n.MinisterText {\n    background-color: #FFF;\n    padding: 30px;\n}\n\n.MinImg {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.MinImg>img {\n    width: 700px;\n    object-fit: contain;\n}\n\n.FullSlider>div>div:last-child {\n    bottom: 30px;\n}\n\n.FullSlider>div>div:last-child>span {\n    width: 50px;\n    height: 10px;\n    border-radius: 0;\n    opacity: 1;\n}\n\n.FullSlider>div>div>span:global(.swiper-pagination-bullet) {\n    width: 50px;\n    height: 10px;\n    border-radius: 0;\n    opacity: 1;\n    background: #FFF;\n}\n\n.FullSlider>div>div>span:global(.swiper-pagination-bullet-active) {\n    background: #0F579E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MinisterBlock": `SumOtherPayment_MinisterBlock__OZ55X`,
	"MinisterImg": `SumOtherPayment_MinisterImg__o+KKu`,
	"MinisterText": `SumOtherPayment_MinisterText__2PAvp`,
	"MinImg": `SumOtherPayment_MinImg__0m-Pj`,
	"FullSlider": `SumOtherPayment_FullSlider__rZ03F`
};
export default ___CSS_LOADER_EXPORT___;
